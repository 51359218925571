import Typography from "@mui/material/Typography";
import { theme } from "../../../../theme/defaultTheme";
import Box from "@mui/material/Box";
import { Divider, FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";

import { APPS, CURRENCIES } from "../../../utils/appConstants";
import UserDepositChanger from "./UserDepositChanger";
import { appPrimeApprovalToggle } from "../../../api/user/appUser";
import UseUserHook from "../useUser.hook";

const TextWrap = ({ label, value }) => {
    return (
        <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
            component="div"
            align="right"
        >
            <Typography variant="subtitle2" sx={{ color: theme.palette.grey[600] }}>
                {label}:{" "}
            </Typography>
            <Typography variant="subtitle2">{value}</Typography>
        </Box>
    );
};

const UserDepositInfo = ({ deposit, title, contract_user, contractId }) => {
    const { fetchUser } = UseUserHook();

    const isPrime = deposit.type.toLowerCase() === APPS.PRIME.toLowerCase();
    let currentCurrency = isPrime ? CURRENCIES.USDT : deposit.currency;

    const [isFetching, setIsFetching] = useState(false);

    const handleIsPrimeApprovedToggle = async (event) => {
        setIsFetching(true);

        await appPrimeApprovalToggle(deposit.id, event.target.checked)
            .then(() => fetchUser())
            .catch((error) => console.log("error: ", error))
            .finally(() => setIsFetching(false));
    };

    return (
        <Box>
            {isPrime && (
                <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                    component="div"
                    align="right"
                >
                    <Typography
                        variant="subtitle2"
                        sx={{ color: theme.palette.grey[600], marginRight: 1 }}
                    >
                        Approved:
                    </Typography>

                    <FormControlLabel
                        sx={{
                            display: "block",
                        }}
                        control={
                            <Switch
                                checked={deposit?.readyToUse ?? false}
                                disabled={isFetching}
                                onChange={handleIsPrimeApprovedToggle}
                                name="loading"
                                color="primary"
                                size="small"
                            />
                        }
                    />
                </Box>
            )}

            <TextWrap label="Status" value={deposit.status} />
            <TextWrap
                label="Deposit"
                value={`${deposit.amount_start} ${currentCurrency}`}
            />
            {deposit.type.toLowerCase() === APPS.PRIME.toLowerCase() && (
                <TextWrap label="Platform" value={deposit.platform} />
            )}
            {deposit.type.toLowerCase() !== APPS.PRIME.toLowerCase() && (
                <TextWrap label="Term" value={`${deposit.term} m.`} />
            )}
            {deposit.type.toLowerCase() !== APPS.PRIME.toLowerCase() && (
                <TextWrap
                    label="Total Percent"
                    value={`${deposit.current_interest_rate} %`}
                />
            )}
            <TextWrap
                label="Balance"
                value={`${deposit.amount_current} ${currentCurrency}`}
            />
            <TextWrap
                label="Income Percent"
                value={`${deposit.total_percentage} %`}
            />
            <TextWrap label="Total Income" value={deposit.total_income} />
            {deposit.type.toLowerCase() === APPS.PRIME.toLowerCase() && (
                <TextWrap label="Expired Date" value={deposit.expiredDate} />
            )}

            <UserDepositChanger
                deposit={deposit}
                title={title}
                contract_user={contract_user}
                contractId={contractId}
            />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </Box>
    );
};

export default UserDepositInfo;
